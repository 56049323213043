<template>
  <div>
    <h2>Glossario</h2>
    <nav class="glossary-nav">
      <ul v-if="glossary.length > 0">
        <li v-for="glossaryTerm in glossary" :key="glossaryTerm.id">
          <router-link :to="'#glossary-term-' + glossaryTerm.id">
            {{ glossaryTerm.term }}
          </router-link>
        </li>
      </ul>
    </nav>
    <dl class="glossary-list">
      <template v-for="glossaryTerm in glossary">
        <dt :id="'glossary-term-' + glossaryTerm.id" :data-bookmark="glossaryTerm.id" :key="'term-name-' + glossaryTerm.id">{{ glossaryTerm.term }}</dt>
        <dd v-html="glossaryTerm.html" :key="'term-' + glossaryTerm.id"></dd>
      </template>
    </dl>
  </div>
</template>

<script>

import glossary from '@/data/glossary.json';

export default {
  data () {
    return {
      glossary: glossary.data || [],
    };
  },
};

</script>

<style lang="scss">
</style>
